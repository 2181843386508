import 'firebase/compat/analytics'
import firebase from 'firebase/compat/app'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useLoggedIn } from '@netpurpose/core'
import { logger } from '@netpurpose/utils'
import { AnalyticsEventName, AnalyticsEventParams } from '#types/analytics'
import { config } from '../config'

const analyticsEnabled =
  config.firebase.analyticsEnabled && config.firebase.measurementId && config.firebase.appId

type LogEvent = (
  eventName: firebase.analytics.EventName | AnalyticsEventName,
  eventParams?: AnalyticsEventParams,
  options?: Parameters<firebase.analytics.Analytics['logEvent']>['2'],
) => void

export type AnalyticsContextType =
  | (Omit<firebase.analytics.Analytics, 'logEvent'> & {
      logEvent: LogEvent
    })
  | null

const defaultContext: AnalyticsContextType = null

export const AnalyticsContext = React.createContext<AnalyticsContextType>(defaultContext)

export const AnalyticsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useLoggedIn()
  const [tracking, setTracking] = useState<AnalyticsContextType>(defaultContext)

  const userId = user?.id

  useEffect(() => {
    // Disable analytics for local development.
    if (analyticsEnabled) {
      firebase.analytics.isSupported().then((isSupported) => {
        if (!isSupported) {
          logger.warn({ err: 'Analytics not supported' })
          return
        }

        setTracking(firebase.analytics())

        if (userId) {
          firebase.analytics().setUserId(userId)
        }
      })
    }
  }, [tracking, userId])

  return <AnalyticsContext.Provider value={tracking}>{children}</AnalyticsContext.Provider>
}

export const useAnalytics = (): AnalyticsContextType => {
  const context = React.useContext(AnalyticsContext)
  if (analyticsEnabled && context === defaultContext) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider')
  }
  return context
}
