import { getApiConfig } from '@netpurpose/api'

export const CDP_FOLDER =
  'https://drive.google.com/drive/folders/1E4t9UGIctPK2zfvtrlPU0zTFJ30uk-uA?usp=sharing'

export const config = getApiConfig()

export const ENV = config.env

export const featureToggles = {
  unleashTest: 'unleash-test-toggle',
  predictionsOnboarding: 'control-tool.predictions-onboarding',
  factNavigationOnboarding: 'control-tool.fact-navigation-onboarding',
  sourceTargetsOnboarding: 'control-tool.source-targets-onboarding',
  factModalSidebarOnboarding: 'control-tool.fact-modal.sidebar.onboarding',
  factAnnotations: 'control-tool.fact-modal.fact-annotations',
} as const

export const onboardingKeys = {
  predictionsLastUsed: 'application:onboarding:predictions:lastUsed',
  factNavigationLastUsed: 'application:onboarding:fact-navigation:lastUsed',
  sourceTargetsLastUsed: 'application:onboarding:source-targets:lastUsed',
  factModalSidebarLastUsed: 'application:onboarding:sidebar:lastUsed',
} as const

export const localStorageKeys = {
  lastUsedTaskId: 'application:collectionTask:currentOrLastActive',
  sourcesViewedTimer: 'application:ingestionTask:sourcesViewedTimer',
  factModalSidebar: 'application:factModal:sidebar',
} as const

const collectionPrefix = '/collection/batches/:workflow'

export const routes = {
  facts: '/facts',
  fact: '/facts/:factId',
  units: '/units',
  addUnit: '/units/add',
  editUnit: '/units/:unitId',
  entities: '/entities',
  addEntity: '/entities/add',
  editEntity: '/entities/:entityId',
  sources: '/sources',
  segments: '/segments',
  addSegment: '/segments/add',
  editSegment: '/segments/:segmentId',
  lookup: '/lookup',
  create: '/create',
  login: '/login',
  dataExtractor: '/data-extractor',
  unleashTest: '/unleash-test',
  users: '/users',
  addUser: '/users/add',
  user: '/users/:userId',
  metrics: '/metrics',
  addMetric: '/metrics/add',
  editMetric: '/metrics/:metricId',
  kpis: '/kpis',
  addKpi: '/kpis/add',
  editKpi: '/kpis/:kpiId',
  groups: '/groups',
  group: '/groups/:groupId',
  collectionStart: '/collection/start',
  collectionPaused: '/collection/paused',
  collectionBatches: '/collection/batches',
  ingestionTaskFact: '/collection/batches/ingestion/:batchId/:taskId/:factId',
  collectionBatchDetailView: `${collectionPrefix}/:batchId`,
  collectionBatchEditPriority: `${collectionPrefix}/priority`,
  collectionTaskDetailView: `${collectionPrefix}/:batchId/:taskId`,
  collectionTaskPaused: `${collectionPrefix}/:batchId/:taskId/paused`,
  semanticSearch: '/semantic-search',
  psi: '/psi',
  // Ideally this would use an estimationId rather than being at /edit
  psiEditEstimations: '/psi/estimations/edit',
  psiEditActivity: '/psi/activities/:activityId',
  psiMacroFactors: '/psi/macro-factors/:themeTaxonId',
  datapointGraph: '/datapoint-graph',
} as const

type Routes = typeof routes
type RouteWithParams = `${string}:${string}`
export type RoutesWithParams = {
  [Route in keyof Routes as Routes[Route] extends RouteWithParams ? Route : never]: Routes[Route]
}

export const routesWithParams = Object.entries(routes).reduce((acc, [key, value]) => {
  if (value.includes(':')) {
    return { ...acc, [key]: value }
  } else {
    return acc
  }
}, {} as RoutesWithParams)

// NOTE: used here instead of with an unleash feature flag as currently Unleash is not 100%
// reliable and this is a significant workflow shift which we need to be able to rely on.
export const HAS_COMBINED_COLLECTION_WORKFLOWS = true

// NOTE: used as the interval for logging time viewing source on ingestion task
export const SOURCE_TIMER_INTERVAL = 1000 // ms
export const REQUIRED_SOURCE_VIEWING_TIME = 60000 // ms
export const REQUIRED_METRIC_CONFIDENCE = 0.645
export const REQUIRED_REPORT_TYPE_CONFIDENCE = 0.5
